<!-- src/components/HomePage.vue -->
<template>
  <div>
    <IntroSite :name="name" :phone="phone" />
    <sectionConquiste :name="name" :phone="phone"/>
    <sectionNichos :name="name" :phone="phone"/>
    <sectionFeatures :name="name" :phone="phone"/>
    <sectionMais :name="name" :phone="phone"/>
    <sectionVideos :name="name" :phone="phone"/>
    <sectionPlanos :name="name" :phone="phone"/>
    <sectionFaq :name="name" :phone="phone"/>
  </div>
</template>

<script>
import IntroSite from './IntroSite.vue'
import sectionConquiste from './sectionConquiste.vue'
import sectionNichos from './sectionNichos.vue'
import sectionFeatures from './sectionFeatures.vue'
import sectionMais from './sectionMais.vue'
import sectionVideos from './sectionVideos.vue'
import sectionPlanos from './sectionPlanos.vue'
import sectionFaq from './sectionFaq.vue'

export default {
  name: 'HomePage',
  components: {
    IntroSite,
    sectionConquiste,
    sectionNichos,
    sectionFeatures,
    sectionMais,
    sectionVideos,
    sectionPlanos,
    sectionFaq,
  },
computed: {
    // Computada para dividir o parâmetro
    name() {
      const [name] = this.$route.params.representative.split('&');
      return name || null;
    },
    phone() {
      const [, phone] = this.$route.params.representative.split('&');
      return phone || null;
    },
  },
};
</script>
