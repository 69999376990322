<template>
<section id="features" class="d-flex align-items-center bg-white centered">
                <div class="container">
                    <div class="row" style="padding: 15px;">
                        <div class="offset-sm-2 col-12 col-sm-8">   
                                    <h1 class="mb-3 text-center">Um jeito único de fazer negócios!</h1>
                                    <p class="mb-3">Converta leads em vendas, automatize conversas através do Whatsapp e organize em um CRM online fácil de usar.
                                    </p>
                                </div>
                    </div>
                    <div class="row" style="padding: 15px;">
                        <div class="offset-sm-2 col-sm-8 col-12">
                            <div class="feature direita animate__animated animate__bounceInRight">
                                <div class="row d-flex align-items-center card-feature purple">
                                    <div class="col-12 col-sm-8">
                                        <h3 class="mb-3">Conexão fácil via QR Code!</h3>
                                        <p class="mb-3">Gere o QR Code e conecte seu Whatsapp facilmente e dispense o celular.
                                        </p>
                                    </div> 
                                
                                <div class="col-12 col-sm-4">
                                    <img src="images/qrcode.png" alt="" class="img-fluid"/>
                                </div>
                            </div>
                        </div>
                            <div class="feature esquerda animate__animated animate__bounceInLeft animate__delay-2s"> 
                                <div class="row d-flex align-items-center card-feature magenta">
                                    <div class="col-12 col-sm-4">
                                        <img src="images/conexoes.png" alt="" class="img-fluid"/>
                                    </div>
                                    <div class="col-12 col-sm-8">
                                        <h3 class="mb-3">Conecte vários números em uma mesma tela atendimento!</h3>
                                        <p class="mb-3">Coloque todos os números de atendimento de sua empresa em um único sistema e distribua os chamados entre os atendentes de modo fácil.
                                        </p>
                                    </div>                                
                            </div>
                            </div>
                            <div class="feature direita  animate__animated animate__bounceInRight animate__delay-3s">
                                <div class="row d-flex align-items-center card-feature green">
                                    <div class="col-12 col-sm-8">
                                        <h3 class="mb-3">Fluxo de atendimento totalmente personalizado </h3>
                                            <p class="mb-3">Crie fluxos de atendimento baseado em palavras chaves ou conte com nosso time para criar para você.
                                            </p>
                                    </div>
                                
                                    <div class="col-12 col-sm-4">
                                        <img src="images/fluxo.png" alt="" class="img-fluid"/>
                                    </div>
                                </div>
                            </div>
                            <div class="feature esquerda  animate__animated animate__bounceInLeft animate__delay-4s">
                                <div class="row d-flex align-items-center card-feature pink">
                                    <div class="col-12 col-sm-4">
                                        <img src="images/nocode.png" alt="" class="img-fluid"/>
                                    </div>
                                    <div class="col-12 col-sm-8">
                                        <h3 class="mb-3">Feito para quem não entende nada de programação!</h3>
                                        <p class="mb-3">Crie fluxos, funil de venda, organize e classifique clientes sem escrever uma só linha de código!
                                        </p>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>

</template>

<script>
export default {
  name: 'sectionFeatures',
  props: {
    name: {
      type: String,
      default: null,
    },
    phone: {
      type: String,
      default: null,
    },
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-feature {
    padding: 16px;
    border-radius: 24px;
    margin-bottom: 16px;
    background-color: #f3efff;
}
.feature.direita .card-feature div img {
    position: relative;
  }
  .feature.esquerda .card-feature div img {
    position: relative;
    text-align: left;
  }
@media only screen and (min-width: 992px) {

    .feature.direita .card-feature div img {right: -80px;}
    .feature.esquerda .card-feature div img {left: -80px;}
     section p, h3 {text-align: left;}
}
</style>
