// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/components/HomePage.vue'
import Assine from '@/components/Assine.vue'

const routes = [
    {
      path: '/:representative?',
      name: 'HomePage',
      component: HomePage,
      props: route => {
        const [name, phone] = route.params.representative?.split('&') || [null, null];
        return { name, phone };
      },
    },
    {
      path: '/assine',
      name: 'Assine',
      component: Assine
    }
  ]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) { // Removido 'from' e 'savedPosition'
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }
    return { top: 0 }
  }
})


export default router
