<template>
  <Carousel :autoplay="800" :items-to-show="3.5" :wrap-around="false">
    <Slide v-for="(image, index) in images" :key="index">
      <div class="carousel__item"><img :src="image.url" :alt="image.alt" /></div>
    </Slide>

  </Carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

export default {
  name: 'WrapAround',
  props: {
    name: {
      type: String,
      default: null,
    },
    phone: {
      type: String,
      default: null,
    },
  },
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      images: [
        {
          url: "/images/parceiros/1.png",
          alt: "Amazon web services"
        },
        {
          url: "/images/parceiros/2.png",
          alt: "Hetzner"
        },
        {
          url: "/images/parceiros/3.png",
          alt: "Vultr"
        },
        {
          url: "/images/parceiros/4.png",
          alt: "Descrição da imagem 3"
        },
        {
          url: "/images/parceiros/6.png",
          alt: "PM2 Plus"
        }
      ]
    };
  }, 
  
}
</script>
<style scoped>
.carousel__item img{
  max-width: 100%;
  padding: 0px 8px;
}
</style>
