<template>
  <div id="App">
    <NavBar :name2="name" :phone2="phone"/>
    <main>
      <router-view :key="$route.fullPath"></router-view>
    </main>
    <pageFooter  :name="name" :phone="phone"/>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import pageFooter from './components/footer.vue'
import { getConfig } from '@/config';

export default {
  name: 'App',
  components: {
    NavBar,
    pageFooter
  },
  data() {
    return {
      currentUrl: window.location.href,
      config: {}
    };
  },
computed: {
  name() {
    const name = this.$route.params?.representative?.split('&')[0] || null;
    console.log("Nome computado:", name);
    return name;
  },
  phone() {
    const phone = this.$route.params?.representative?.split('&')[1] || null;
    console.log("Telefone computado:", phone);
    return phone;
  },
},

  created () {
      console.log("Parâmetros recebidos no IntroSite:", { name: this.name, phone: this.phone });
    this.config = getConfig(this.currentUrl);
    document.title = this.config.pageTitle;
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--dark-text-color);
  margin-top: 60px;
}
* {
  font-family: 'Poppins', sans-serif;
  transition: all .3s; 
}
:root {
  --animate-delay: 0.5s !important;
}
body {
  box-sizing: border-box;
  color: var(--dark-text-color);
  background-color: var(--light-background-color) !important;
}
a {
  color: var(--green-dark-color) !important;
}
section {
  padding: 48px 0px;
}
section p {
  text-align: center;
}
.btn {
  padding: 18px 38px !important;
}
.btn-primary {
  background-color: var(--green-dark-color) !important;
  border: none !important;
}
@media (min-width: 1200px) {
  .h1, h1 {
    font-size: 2.8rem !important;
  }
  .h4, h4 {
    font-size: 1.2rem !important;
    font-weight: normal !important;
  }
}
</style>
