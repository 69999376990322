<template>
                           <section class="d-flex align-items-center bg-white" style="min-height: 90vh;">
                <div class="container d-flex align-items-center">
                    <div class="row d-flex align-items-center">
                        <div class="col-12 col-sm-6 col-12 col-sm-6">   
                                    <h1 class="mb-3">{{config.secConquisteTitle}}</h1>
                                    <p class="mb-3">{{config.setConquisteDescript}}
                                    </p>
                        </div>
                        <div class="col-12 col-sm-6">
                            <img src="images/tela1.png" alt="" class="img-fluid  animate__animated animate__bounceInRight"/>
                        </div>
                    </div>
                </div>
            </section>
</template>

<script>
import { getConfig } from '@/config';

export default {
  name: 'sectionConquiste',
  props: {
    msg: String
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    phone: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentUrl: window.location.href,
      config: {}
    };
  },
  created() {
    this.config = getConfig(this.currentUrl);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (min-width: 992px) {

    section {text-align: left;}
    section h1, h1 {
        font-size: 3.125em;
        line-height: 48px;
        text-align: left;
    }
    section p {text-align:left;}
    section h4 {text-align: left;}
}
</style>
