<template>
<section id="features" class="d-flex align-items-center bg-white centered">
                <div class="container">
                    <div class="row">
                        <div class="col-12 offset-sm-1 col-sm-10">
                        <div class="row" style="padding: 15px;">
                        <div class="offset-sm-2 col-12 col-sm-8">   
                                    <h1 class="mb-3 text-center">Uma série de recursos que se ajustam ao seu negócio.</h1>
                                   <!-- <p class="mb-3">Converta leads em vendas, automatize conversas através do Whatsapp e organize em um CRM online fácil de usar.
                                    </p> -->
                                </div>
                    </div>
                        <div class="row">
                        <div class="col-6 col-sm-3 d-flex">
                            <div class="item-feature">
                                <div class="item-feature-icon">
                                    <img src="images/features/lista.png" alt="" class="img-fluid"/>
                                </div>
                                <div class="item-feature-title py-2">listas segmentadas</div>
                                <p>Atribua rótulos aos seus leads e crie listas personalizadas.</p>
                            </div>
                        </div>

                        <div class="col-6 col-sm-3 d-flex">
                            <div class="item-feature">
                                <div class="item-feature-icon">
                                     <img src="images/features/mensagem-em-massa.png" alt="" class="img-fluid"/>
                                </div>
                                <div class="item-feature-title py-2">disparos em massa </div>
                                <p>Crie campanhas e envie para suas listas de contato personalizadas.</p>
                            </div>
                        </div>

                        <div class="col-6 col-sm-3 d-flex">
                            <div class="item-feature">
                                <div class="item-feature-icon">
                                    <img src="images/features/chat-interno.png" alt="" class="img-fluid"/>
                                </div>
                                <div class="item-feature-title py-2">chat interno</div>
                                <p>Use o chat para se comunicar com sua equipe internamente de forma rápida e prática.</p>
                            </div>
                        </div>

                        <div class="col-6 col-sm-3 d-flex">
                            <div class="item-feature">
                                <div class="item-feature-icon">
                                    <img src="images/features/funil.png" alt="" class="img-fluid"/>
                                </div>
                                <div class="item-feature-title py-2">Funil de vendas</div>
                                <p>Crie funis de venda e classifique seus leads para um melhor acompanhamento do atendimento.</p>
                            </div>
                        </div>

                        <div class="col-6 col-sm-3 d-flex">
                            <div class="item-feature">
                                <div class="item-feature-icon">
                                    <img src="images/features/agendamento.png" alt="" class="img-fluid"/>
                                </div>
                                <div class="item-feature-title py-2">Agendamento de mensagens</div>
                                <p>Configure data e hora para entrar em contato com um cliente automaticamente.</p>
                            </div>
                        </div>

                        <div class="col-6 col-sm-3 d-flex">
                            <div class="item-feature">
                                <div class="item-feature-icon">
                                    <img src="images/features/construtor-de-fluxo.png" alt="" class="img-fluid"/>
                                </div>
                                <div class="item-feature-title py-2">Construtor de fluxo arrasta e solta</div>
                                 <p>Crie fluxos de atendimento apenas arrastando e soltando. </p>
                            </div>
                        </div>

                        <div class="col-6 col-sm-3 d-flex">
                            <div class="item-feature">
                                <div class="item-feature-icon">
                                    <img src="images/features/ia.png" alt="" class="img-fluid"/>
                                </div>
                                <div class="item-feature-title py-2">Inteligência artificial</div>
                                 <p>Integração disponível com o ChatGPT para tornar o fluxo de atendimento ainda mais fluído e inteligente.</p>
                            </div>
                        </div>

                        <div class="col-6 col-sm-3 d-flex">
                            <div class="item-feature">
                                <div class="item-feature-icon"  style="">
                                    <img src="images/features/construtor-de-fluxo.png" alt="" class="img-fluid"/>
                                </div>
                                <div class="item-feature-title py-2">Api & Webhooks</div>
                                <p>Integração com Typebot, Flow Wise, Corban, Mercado Pago, RD Station,  Zaia, CVCRM e muitas outras.</p>
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                    
                </div>
            </section>

</template>

<script>
export default {
  name: 'sectionMais',
  props: {
    name: {
      type: String,
      default: null,
    },
    phone: {
      type: String,
      default: null,
    },
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.item-feature{
    display: flex;
    flex-direction: column;
      justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-radius:16px;
    border: 1px solid aliceblue;
    margin-bottom: 16px;
}
.item-feature-title{
    text-transform: uppercase;
    font-size:14px;
    font-weight: 500;
}
.item-feature:hover{
    background-color:#f3efff;
    border: none;
}

.item-feature-icon {
    position: relative;
  width: 101px;
  height: 90px;
  background-image: url('../../public/images/item-feature-bg.png');
} 
.item-feature p{
  font-weight: 300;
  line-height: 1.4;
  font-size: 14px;
}
</style>
